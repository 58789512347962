<template>
  <div class="c-hot-music-list">
    <ul class="row" v-for="d of mockData" :key="d.id">
      <li class="row__item">
        <ul class="hot-music-list item-hover--left">
          <li class="hot-music-list__item hot-music-list__item--version-info" v-if="aboveTablet">
            <div class="versions-info">
              <cover-name :data="d" />
            </div>
          </li>
          <li class="hot-music-list__item hot-music-list__item--version-info" v-else>
            <div class="versions-info">
              <cover-name-code :data="d" :ml-offset="false" />
            </div>
          </li>

          <li class="hot-music-list__item hot-music-list__item--function" v-if="aboveTablet">
            <div class="versions-info">
              <music-function :functionList="['add', 'star', 'download', 'note', 'share']" showPlay />
            </div>
          </li>
          <li class="hot-music-list__item hot-music-list__item--length">
            <div class="versions-info">
              <time-length :data="d" />
            </div>
          </li>
          <li class="hot-music-list__item hot-music-list__item--id" v-if="aboveTablet">
            <div class="versions-info">
              <id :data="d" />
            </div>
          </li>
          <li class="hot-music-list__item hot-music-list__item--more" v-else>
            <div class="versions-info">
              <More :data="d" />
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import CoverNameCode from '@/components/Global/MusicPlayer/Fragment/CoverNameCode.vue';
import CoverName from '@/components/Global/MusicPlayer/Fragment/CoverName.vue';
import More from '@/components/Global/MusicPlayer/Fragment/More.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import Id from '@/components/Global/MusicPlayer/Fragment/Id.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'HotMusicList',
  components: {
    CoverNameCode,
    TimeLength,
    More,
    MusicFunction,
    CoverName,
    Id,
  },

  setup() {
    const siteStore = useSiteStore();

    const mockData = [
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-1',
      },
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-2',
      },
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-3',
      },
    ];

    const aboveTablet = computed(() => {
      if (siteStore.deviceType.includes('tablet')) {
        return true;
      }

      return false;
    });

    return {
      mockData,
      aboveTablet,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-hot-music-list {
  width: 100%;
}
.row {
  &__item {
    border-radius: 4px;
    background-color: $c-white;
    box-shadow: 0px 0px 10px rgba($c-black, 0.1);
  }

  & + & {
    margin-top: 20px;
  }
}

.hot-music-list {
  @include flex();

  &__item {
    width: 100%;

    &--version-info {
      flex: 1;
    }

    &--function {
      @include flex(center);
      flex: none;
      width: 350px;
    }

    &--length {
      @include flex(center);
      flex: none;
      width: 45px;
    }

    &--more {
      @include flex(center);
      flex: none;
      width: 45px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .hot-music-list {
    @include flex(center);

    &__item {
      height: 78px;

      &--version-info {
        @include flex(flex-start);
        flex: 1;
        margin-left: 20px;
      }

      &--function {
        flex: 1;
        @include flex(flex-start);
      }

      &--length {
        width: 100px;
      }

      &--id {
        @include flex(center);
        flex: none;
        width: 100px;
      }
    }
  }
}
</style>
